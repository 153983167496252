<template>
  <div id="vue-page-loaded" v-if="($root.dataLoaded || $config.editMode)">
    <pg-header
      :items="header.headerItems"
      :brandName="common.brandName"
      :brandNodeName="common.brandNodeName"
      :brandNodePath="common.brandNodePath"
      :services="header.services"
      :logo="header.logo"
      :logoOnTransparent="header.logoOnTransparent"
      :layout="header.listStyle"
      :hide-navigation="navigation.hideNavigation"
      v-if="!common.iframe"
    />
    <main-navigation
      :menuItemsObj="mainNavigation.mainNavigationItems"
      :highlightItems="mainNavigation.highlightItems || []"
      :common="common"
    ></main-navigation>
    <floating-shortcuts
      v-if="!common.iframe && common.floatingShortcuts && common.floatingShortcuts.length > 0"
      :floatingShortcuts="common.floatingShortcuts"
      :actpageFullUrl="common.actpageFullUrl"
    />
    <santader-fin-sim
        v-if="common.santanderFinancialSimulator && common.santanderFinancialSimulator !== null"
        :commonData="common"
        :modelData="model"
    ></santader-fin-sim>
    <!-- page vue component must declare a <slot>. It works like the <jsp:doBody/> -->

    <main id="mainContent">
      <slot></slot>
    </main>

    <pg-footer
      :logo="footer.logo"
      :items="footer.footerColumns || []"
      :brandName="common.brandName"
      :brandNodeName="common.brandNodeName"
      :site-service-param="common.siteServiceParam"
      :brandNodePath="common.brandNodePath"
      :languages="common.changeLanguages"
      :currentLocale="common.currentLocale"
      :socialLinks="common.socialLinks"
      :legalNote="footer.legalNote"
      :currentCountry="common.currentCountry"
      :currentLanguage="common.currentLanguage"
      :disableChangeLang="common.disableChangeLang"
      :includeCommunityFooter="model.includePreFooter"
      :communityFooterData="{
        iconName: common.communityData ? common.communityData.iconNamePreFooter : null,
        iconTitle: common.communityData ? common.communityData.iconTitlePreFooter : null,
        contact1: common.communityData ? common.communityData.contact1PreFooter : null,
        contact2: common.communityData ? common.communityData.contact2PreFooter : null
      }"
      v-if="!common.iframe"
      :show-flags="common.showFlags"
    ></pg-footer>

    <marketing-tool-modal v-if="!common.iframe" :common="common"/>
    <!-- BISOGNA AGIRE QUI PER IL PULSANTE GOTOTOP  -->
    <go-to-top v-if="model.goToTop || this.common.goToTop"></go-to-top>
  </div>

  <pg-loader v-else></pg-loader>
</template>

<script>
  import { mgnlComponentMixin } from "../mixins/mixins.js";
  import SantaderFinSim from "./SantanderFinSim/SantaderFinSim";
  import PgHeader               from "./Header/Header.vue";
  import MainNavigation from "./MainNavigation/MainNavigation.vue";
  import PgLoader from "./Loader/Loader.vue";
  import PgFooter from "./Footer/Footer.vue";
  import FloatingShortcuts from "./FloatingShortcuts/FloatingShortcuts.vue";
  import MarketingToolModal from "./MarketingToolModal/MarketingToolModal";
  import GoToTop from "./GoToTop/GoToTop";

  export default {
    mixins: [mgnlComponentMixin],

    name: "VuePageLayout",

    data() {
      return {

      };
    },
    components: {
      MainNavigation,
      SantaderFinSim,
      PgHeader,
      PgFooter,
      PgLoader,
      MarketingToolModal,
      FloatingShortcuts,
      GoToTop
    },
    computed: {
      navigation: function() {
        return this.model.navigation || {};
      },
      header: function() {
        return (this.navigation && this.navigation.header) || {};
      },
      mainNavigation: function() {
        return (
          (this.navigation && this.navigation.mainNavigation) || {}
        );
      },
      footer: function() {
        return (
          (this.navigation && this.navigation.footerNavigation) || {}
        );
      }
    }
  };
</script>
