import Vue                                                  from "vue";
import VueConfig                                            from "vue-config";
import EventHub                                             from "vue-event-hub";
import DebugPanel                                           from "./components/DebugPanel.vue";
import VuePageLayout                                        from "./components/VuePageLayout.vue";
import Msg                                                  from "./components/Msg/Msg.vue";
import axios                                                from "axios";
import VueAxios                                             from "vue-axios";
import SocialSharing                                        from "vue-social-sharing";
import { mgnlLink, mgnlMedia, strippedContent, subStr, formatPrice }     from "./filters/common";
import Vue2Filters                                          from "vue2-filters"; // https://www.npmjs.com/package/vue2-filters
import VueMq                                                from "vue-mq"; // https://github.com/AlexandreBonaventure/vue-mq
import whatInput                                            from "what-input"; // https://github.com/ten1seven/what-input
import Vuelidate                                            from 'vuelidate' // https://vuelidate.js.org/
import {
  FormCheckboxPlugin,
  FormGroupPlugin,
  FormInputPlugin,
  FormRadioPlugin,
  FormSelectPlugin,
  FormTextareaPlugin,
  FormDatepickerPlugin,
  ModalPlugin,
  TabsPlugin
}                                                           from "bootstrap-vue"; // https://bootstrap-vue.js.org/docs/components/
import Hooper                                               from "hooper"; // carousel https://github.com/baianat/hooper

import MsgPlugin          from "./plugins/msg";
import gtmManager         from "./plugins/gtm-manager";
import * as VueGoogleMaps from "vue2-google-maps";

import VDragged from 'v-dragged';

import VueQRCodeComponent from 'vue-qrcode-component'

const mgnlVueConfig = window.mgnlVueConfig || {};

// Setup vue configurations
Vue.config.productionTip = false;

// Remove Vue Warn when custom tags are included in templates
Vue.config.ignoredElements = [ "scf-quoting" ];

// Initializing plugins
Vue.use(VueAxios, axios);
Vue.use(VueConfig, mgnlVueConfig);
Vue.use(EventHub);
Vue.use(SocialSharing);
Vue.use(Vue2Filters);
Vue.use(MsgPlugin, {mode: mgnlVueConfig.messageMode});
Vue.use(gtmManager);
Vue.use(VueMq, {
  breakpoints: {
    small: 640,
    medium: 1024,
    large: Infinity
  }
});
Vue.use(whatInput);
Vue.use(TabsPlugin);
Vue.use(ModalPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(FormGroupPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormRadioPlugin);
Vue.use(FormSelectPlugin);
Vue.use(FormDatepickerPlugin);
Vue.use(FormTextareaPlugin);
Vue.use(Hooper);
Vue.use(Vuelidate);
Vue.use(VueGoogleMaps, {
  load: {
    key: mgnlVueConfig.googleMapKey,
    libraries: "places",
    region: mgnlVueConfig.currentCountry,
    language: mgnlVueConfig.currentLanguage
  },
  installComponents: false
});

Vue.use(VDragged);

// Registering global components
Vue.component("msg", Msg);
Vue.component("debug-panel", DebugPanel); //TODO load only in dev mode
Vue.component('qr-code', VueQRCodeComponent)

//Registering global filters
Vue.filter("mgnlMedia", mgnlMedia);
Vue.filter("mgnlLink", mgnlLink);
Vue.filter("strippedContent", strippedContent);
Vue.filter("subStr", subStr);
Vue.filter("formatPrice", formatPrice);

new Vue({
  el: "#vue-page",

  // Declaring all vue component that correspond to mgnl components
  components: {
    VuePageLayout, // page layout is the vue component that represents the whole magnolia page
    MgnlHeader: () => import("./components/Header/MgnlHeader.vue"),
    MgnlFooter: () => import("./components/Footer/MgnlFooter.vue"),
    MgnlFooterColumn: () => import("./components/Footer/MgnlFooterColumn.vue"),
    MgnlMainNavigation: () =>
        import("./components/MainNavigation/MgnlMainNavigation.vue"),
    MgnlMainNavigationItem: () =>
        import("./components/MainNavigation/MgnlMainNavigationItem.vue"),
    Editorial: () => import("./components/Editorial/Editorial.vue"),
    EditorialImmersive: () => import("@wl-components/Editorial/EditorialHorizontalImmersive.vue"),
    MgnlEditorial: () => import("./components/Editorial/MgnlEditorial.vue"),
    MgnlEditorialImmersive: () => import("./components/Editorial/MgnlEditorialImmersive.vue"),
    EditorialArea: () => import("./components/EditorialArea/EditorialArea.vue"),
    HotspotCarouselArea: () => import("./components/HotspotCarouselArea/HotspotCarouselArea.vue"),
    //MgnlEditorialArea: () => import("./components/EditorialArea/MgnlEditorialArea.vue"),
    EditorialQuote: () =>
        import("./components/EditorialQuote/EditorialQuote.vue"),
    EditorialIcon: () => import("./components/EditorialIcon/EditorialIcon.vue"),
    EditorialTabs: () => import("./components/EditorialTabs/EditorialTabs.vue"),
    EditorialCoverImage: () =>
        import("./components/EditorialCoverImage/EditorialCoverImage.vue"),
    CarouselV1: () => import("./components/CarouselV1/CarouselV1.vue"),
    CarouselV2: () => import("./components/CarouselV2/CarouselV2.vue"),
    CarouselV3: () => import("./components/CarouselV3/CarouselV3.vue"),
    CarouselMotoplex: () => import("./components/CarouselMotoplex/CarouselMotoplex.vue"),
    ProductPresentation: () =>
        import("./components/ProductPresentation/ProductPresentation.vue"),
    ProductPresentationImmersive: () =>
        import("./components/ProductPresentationImmersive/ProductPresentationImmersive.vue"),
    Hotspot: () => import("./components/Hotspot/MgnlHotspot.vue"),
    HotspotItem: () => import("./components/Hotspot/HotspotItem.vue"),
    IframeDlMap: () => import("./components/IframeDlMap/IframeDlMap.vue"),
    Introduction: () => import("./components/Introduction/Introduction.vue"),
    MgnlListingArticlesWithFeatured: () =>
        import("./components/Listing/MgnlListingArticlesWithFeatured.vue"),
    MgnlCardPromotion: () =>
        import("./components/Card-Promotion/MgnlCard-Promotion.vue"),
    Divider: () => import("./components/Divider/Divider.vue"),
    MgnlDynamicWrapper: () => import("./components/MgnlDynamicWrapper/MgnlDynamicWrapper.vue"),
    FloatingShortcuts: () => import("./components/FloatingShortcuts/FloatingShortcuts.vue"),
    FinancialSimulator: () => import("./components/FinancialSimulator/FinancialSimulator.vue"),
    HubLinks: () => import("./components/HubLinks/HubLinks.vue"),
    CardsWrapper: () => import("./components/CardWrapper/CardsWrapper.vue"),
    CardsWrapperCommunity: () => import("./components/CardWrapperCommunity/CardsWrapperCommunity.vue"),
    HeroSingleContent: () => import("./components/HeroSingleContent/HeroSingleContent.vue"),
    PromotionCtaList: () => import("./components/PromotionCtaList/PromotionCtaList.vue"),
    FormUpload: () => import("./components/FormUpload/FormUpload.vue"),
    EventsCalendar: () =>
      import("./components/EventsCalendar/EventsCalendar.vue"),
  },

  comments: true, // comments attr must be true in order to let edit mode working as expected

  template: "#vue-page-tmpl",

  data: function () {
    return {
      pageData: {},
      translations: {},
      commonData: {},
      dataLoaded: false,
      areaModel: null
    };
  },

  mounted: function () {
    this.$config.editMode &&
    this.$nextTick(() => {
      //fix vaadin bars
    });
  },

  created: function () {
    // In every page the ajax variations is called async in order to popolate the root data element.
    // This data will be retrieved through the computed properties inherited by the mgnlComponent mixin (check mixins.js)
    let ctx = this;

    let queryString = window.queryString ? window.queryString : "";

    ctx.$http
        .get(
            this.$config.apiPath +
            "?ajax=true" +
            (queryString && !queryString.startsWith("&") ? "&" : "") +
            queryString
        )
        .then(response => {
          ctx.pageData = response.data.pageData;
          ctx.translations = response.data.translations;
          ctx.commonData = response.data.commonData;
          ctx.dataLoaded = true;
        });
  }
});
