<template>
  <section id="scf-container" v-if="isSantanderSimulatorEnabled(commonData.santanderFinancialSimulator)">
    <scf-quoting
        class="container-scf"
        type="sidebarRight"
        :calculation="prepareSantanderData()">
    </scf-quoting>
  </section>
</template>

<script>
export default {
  name: "SantaderFinSim",
  props: [
      'commonData',
      'modelData'
  ],

  data() {
    return {
      currentDataProduct: null
    }
  },

  created() {
    this.$eventHub.on('currentDataProduct', eventParams => {
      this.currentDataProduct = Object.assign({}, eventParams);
    })
  },

  methods: {
    isSantanderSimulatorEnabled(santanderFinSim) {
      return santanderFinSim &&
          santanderFinSim.santanderEnabledForBrand &&
          santanderFinSim.santanderEnabledForCountry &&
          santanderFinSim.santanderPluginId
    },

    prepareSantanderData() {
      let pdp = 'product-presentation';
      return JSON.stringify({
        "country": this.commonData?.currentCountry,
        "language": this.commonData?.currentLanguage,
        "assetId": this.currentDataProduct?.financingCode,
        "assetPrice": this.currentDataProduct?.discountedPrice * 1 || this.currentDataProduct?.price * 1,
        "model": this.modelData[pdp][Object.keys(this.modelData[pdp])[0]].name.trim(),
        "assetImage": this.currentDataProduct?.images[0].small
      })
    }
  }
}
</script>