<template>
  <nav
      id="navigation"
      class="navigation"
      :class="{ 'is-visible': visible || isEditInterface }"
      :aria-label="secondaryAriaLabel"
      ref="allFocusable"
  >
    <div class="graphic-line"><!-- --></div>
    <div class="navigation__structure-div">
        <div class="navigation__search">
          <!-- search -->
        </div>
      <div class="main-navigation-index" :aria-label="$msg('common.secondary.navigation-modal-aria-label')">
        <a href="#" class="main-navigation-description show-on-focus"><msg id="common.secondary.navigation-modal-text" /></a>
      </div>
        <div class="navigation__change-lan-cou show-for-large">
          <change-language-country
              :uuid="'inMainNavigation'"
              :brandName="common.brandName"
              :brandNodeName="common.brandNodeName"
              :site-service-param="common.siteServiceParam"
              :languages="common.changeLanguages"
              :currentLocale="common.currentLocale"
              :disableChangeLang="common.disableChangeLang"
              :currentCountry="common.currentCountry"
              :show-flags="common.showFlags"
          />
        </div>
        <div class="navigation__content-wrapper">
          <div class="navigation__content">
            <div class="navigation__menu">
              <ul aria-label="Main navigation">
                <li v-for="(item, index) in menuItemsObj" v-bind:key="item.uuid">
                  <pg-button
                      :class="'navigation__menu__item'"
                      :aria-expanded="activeElement(item.uuid, index).toString()"
                      event-name="expandNested"
                      :event-params="{ uuid: item.uuid }"
                      :aria-controls="'nav-submenu-' + item.uuid"
                      :analytics-data="analyticsData(item)"
                      v-if="item.nestedItems || item['menu-tabs-area']"
                  >
                    {{ item.label }}
                    <i class="icon-arrow-dx" aria-hidden="true"></i>
                  </pg-button>
                  <pg-button
                      :class="'navigation__menu__item'"
                      :href="item.url | mgnlLink('url')"
                      :targetBlank="item.onBlank ? true : false"
                      :analytics-data="analyticsData(item)"
                      v-else
                  >
                    {{ item.label }}
                  </pg-button>
                </li>
              </ul>
            </div>
            <div
                class="navigation__highlights"
                :class="isHighlightOrBackgroundVisible"
                v-if="hasHighlightItems"
            >
              <card-highlight
                  :href="item.link | mgnlLink('url')"
                  :text="item.text"
                  :cta="item.label"
                  :targetBlank="item.targetBlank"
                  :img="item.image | mgnlMedia('url')"
                  v-for="item in highlightItems"
                  :key="item.uuid"
              ></card-highlight>
            </div>
            <div
                class="navigation__background"
                :class="isHighlightOrBackgroundVisible"
                v-else-if="hasPosterImage"
            >
              <div>
                <pg-image :lazy-src="posterImage | mgnlMedia('url')"></pg-image>
              </div>
            </div>
            <div class="navigation__change-lan-cou hide-for-large">
              <change-language-country
                  :uuid="'inMainNavigationMenu'"
                  :brandName="common.brandName"
                  :brandNodeName="common.brandNodeName"
                  :site-service-param="common.siteServiceParam"
                  :languages="common.changeLanguages"
                  :currentLocale="common.currentLocale"
                  :disableChangeLang="common.disableChangeLang"
                  :currentCountry="common.currentCountry"
                  :show-flags="common.showFlags"
              />
            </div>
            <template v-if="!isEditInterface">
              <main-navigation-item
                  v-for="(item, index) in menuItemsObj"
                  :key="item.uuid"
                  :item="item"
                  :index="index"
                  :highlightItems="item.highlightItems"
                  :posterImage="item.posterImage"
                  :isActiveElement="activeElement(item.uuid, index)"
                  :productCardConf="common.productCardConf"
              />
            </template>
            <template v-else>
              <slot></slot>
            </template>
          </div>
        </div>
      </div>
    <div class="close-main-navigation" :aria-label="$msg('common.secondary.navigation-modal-close')">
      <pg-button
          v-if="!hideNavigation"
          :selfManageStatus="false"
          eventName="header:toggleNavigation"
          styleType="icon"
          class="show-on-focus"
          :aria-expanded="isNavigationOpen"
          icon="icon-menu"
          aria-controls="navigation"
      >
        <msg id="common.header.hamburger-menu.aria" />
      </pg-button>
    </div>
  </nav>
</template>

<script>
import PgButton from "../Button/Button.vue";
import PgImage from "../Image/Image.vue";
import CardHighlight from "../Card-Highlight/Card-Highlight.vue";
import ChangeLanguageCountry from "../ChangeLanguageCountry/ChangeLanguageCountry.vue";
import MainNavigationItem from "./MainNavigationItem";

export default {
  name: "MainNavigation",

  components: {
    PgButton,
    PgImage,
    ChangeLanguageCountry,
    CardHighlight,
    MainNavigationItem
  },

  data() {
    return {
      visible: false,
      currentActive: null,
      isNavigationOpen: true,
    };
  },

  props: {
    common: {
      type: Object,
    },
    menuItemsObj: {
      type: Array,
      default: () => [],
    },
    highlightItems: {
      type: Array,
      required: false,
      default: () => [],
    },
    posterImage: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    isEditInterface: {
      type: Boolean,
      default: false,
    },
    hideNavigation: {
      type: Boolean,
      default: false
    },
  },

  computed: {
    activeElement() {
      const ctx = this;
      return function (uuid, index) {
        let conditionEquality = ctx.currentActive === uuid;
        let conditionDefault =
            ctx.highlightItems.length == 0 &&
            Object.keys(ctx.posterImage).length == 0 &&
            !ctx.currentActive &&
            this.$mq == "large" &&
            index == 0;
        return conditionEquality || conditionDefault;
      };
    },

    isHighlightOrBackgroundVisible() {
      return this.currentActive === null ? "is-active" : "";
    },

    hasHighlightItems() {
      return this.highlightItems && this.highlightItems.length > 0;
    },

    hasPosterImage() {
      return this.posterImage && Object.keys(this.posterImage).length > 0;
    },
    secondaryAriaLabel() {
      return this.$msg('common.secondary.aria-label.text') //TODO add fallback in case msg not configured
    }
  },
  methods: {
    isHidden(el) {
      if (
          window.getComputedStyle(el).visibility === "hidden" ||
          el.offsetParent === null
      ) {
        return true;
      }
    },
    handleKeyboard(event) {
      // A11Y KEYBOARD ESC + MODAL TRAP

      const allFocusableList = this.$refs.allFocusable.querySelectorAll(
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      );

      let allFocusableListVisible = [];

      allFocusableList.forEach((element) => {
        if (!this.isHidden(element)) {
          allFocusableListVisible.push(element);
        }
      });

      const last = allFocusableListVisible.length - 1;

      /* console.log(
        "handleKeyboard",
        event.key,
        event.target,
        firstfocusableList[0],
        lastfocusableList[last]
      );*/

      if (event.key === "Escape") {
        //this.$modal.hide("mymyodal")
        if (this.visible) {
          this.$eventHub.emit("header:toggleNavigation");
        }
      }

      /*console.log(
        "first",
        allFocusableListVisible[0],
        "last",
        allFocusableListVisible[last]
      );
      */

      if (
          event.key === "Tab" &&
          event.shiftKey === false &&
          event.target === allFocusableListVisible[last]
      ) {
        event.preventDefault();
        allFocusableListVisible[0].focus();
      } else if (
          event.key === "Tab" &&
          event.shiftKey === true &&
          event.target === allFocusableListVisible[0]
      ) {
        event.preventDefault();
        allFocusableListVisible[last].focus();
      }
    },
    analyticsData(el) {
      return {
        evCategory: 'menu',
        evAction: 'menu_principale',
        evLabel: el.label.trim()
      }
    }
  },
  created() {
    document.addEventListener("keydown", this.handleKeyboard);
    const ctx = this;

    this.$eventHub.on("header:toggleNavigation", function () {
      const bodyEl = document.body;
      const inertEls = document.querySelectorAll("#mainContent, #footer");

      ctx.visible = !ctx.visible;

      ctx.visible
          ? bodyEl.classList.add("hidden-scroll", "main-navigation-open")
          : bodyEl.classList.remove("hidden-scroll", "main-navigation-open");

      for (var i = 0; i < inertEls.length; i++) {
        var inertEl = inertEls[i];

        ctx.visible
            ? inertEl.setAttribute("hidden", true)
            : inertEl.removeAttribute("hidden");
      }
    });

    this.$eventHub.on("expandNested", function (eventParams) {
      //console.log(eventParams);
      const navigationEl = document.getElementById("navigation");

      let active = eventParams && eventParams.uuid;
      ctx.currentActive = ctx.currentActive != active ? active : null;

      if (ctx.$mq == "small") {
        ctx.currentActive
            ? navigationEl.classList.add(
            "overflow-hidden",
            "main-navigation-open"
            )
            : navigationEl.classList.remove(
            "overflow-hidden",
            "main-navigation-open"
            );
      }
    });

  },

  destroyed() {
    document.removeEventListener("keyup", this.close);
  },
};
</script>
<style scoped>
#root > .navigation {
  display: flex;
  top: 0;
  height: 100vh;
}
</style>
